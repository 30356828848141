<template>
  <div>
    <el-form
      :model="editFollowInfo"
      ref="editFollowInfo"
      :rules="editFollowInfoRules"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="开始时间" label-width="100px" prop="starttime">
            <el-date-picker
              v-model="editFollowInfo.starttime"
              type="datetime"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择跟进开始时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="类型" label-width="100px" prop="type">
            <el-select
              v-model="editFollowInfo.type"
              placeholder="请选择跟进类型"
            >
              <el-option
                v-for="item in types"
                :key="item"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="目标" label-width="100px" prop="target">
            <el-input
              type="textarea"
              :rows="5"
              maxlength="500"
              v-model="editFollowInfo.target"
              placeholder="请输入跟进目标"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="edit_save()">确定</el-button>
      <el-button v-if="followPlanId" @click="handler_closeFollow()"
        >关闭</el-button
      >
    </div>
  </div>
</template>

<script>
import CUSTOM from "../../api/biz/api_customer";
import FOLLOW from "../../api/biz/api_followplan";
import moment from "moment";

export default {
  name: "followEdit",
  data() {
    return {
      customerId: undefined,
      followPlanId: undefined,
      editFollowInfo: {},
      types: [
        { name: "新客开拓", value: 0 },
        { name: "创建年度验证计划", value: 1 },
      ],
      editFollowInfoRules: {
        target: [
          { required: true, message: "请输入跟进目标", trigger: "blur" },
          {
            min: 2,
            max: 500,
            message: "长度在 2 到 500 个字符",
            trigger: "blur",
          },
        ],
        starttime: [
          {
            type: "date",
            required: true,
            message: "请输入跟进开始时间",
            trigger: "blur",
          },
        ],
        //type: [{ reuqired: true, message: "请输入跟进类型", trigger: "blur" }],
      },
      auth: {}
    };
  },
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/followList');
      this.initPageData();
    },
    initPageData() {
      this.customerId = this.$route.query.customerId;
      this.followPlanId = this.$route.query.followPlanId;
      if (this.customerId && this.customerId > 0) {
        this.editFollowInfo = {};
        this.editFollowInfo.type = 0;
        let current = Date.now();
        this.editFollowInfo.starttime = moment(current).format(
          "YYYY-MM-DD 00:00:00"
        );
      } else if (this.followPlanId && this.followPlanId > 0) {
        FOLLOW.get({
          id: this.followPlanId,
        }).then((res) => {
          if (200 == res.code) {
            this.editFollowInfo = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.error("缺少请求参数");
      }
    },
    edit_save() {
      this.$refs["editFollowInfo"].validate((valid) => {
        if (valid) {
          if (this.followPlanId) {
            this.editFollowInfo.id = this.followPlanId;
          } else if (this.customerId) {
            this.editFollowInfo.custid = this.customerId;
          }
          this.editFollowInfo.starttime = moment(
            this.editFollowInfo.starttime
          ).format("YYYY-MM-DD HH:mm:ss");
          FOLLOW.info(this.editFollowInfo)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.followPlanId = res.data?.id;
                this.customerId = undefined;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        }
      });
    },
    handler_closeFollow() {
      this.$confirm("此操作将关闭跟进计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = "id=" + this.followPlanId;
          FOLLOW.close(params)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.$router.push({ path: '../followList', query: {} });
              } else {
                this.$message.error(res?.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.demo-ruleForm {
  margin-top: 10px;
}
</style>